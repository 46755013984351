import { Box, Text } from "@chakra-ui/react";
import { FC } from "react";
type BrandLogoProps = {
 size?: any;
}
export const BrandLogo: FC<BrandLogoProps> = ( {size= { base: 18, md: 22 }}) => {
  return (
    <Box>
      <Text
        fontSize={size}
        fontWeight={400}
        color={"#000"}
        ml={{ base: 0, sm: 1 }}
      >
        TheLast
        <Text
          as={"span"}
          color={"#F26B3A"}
          borderTop={"2px #F26B3A solid"}
          borderBottom={"2px #F26B3A solid"}
          borderRadius={7}
          p={"0 3px"}
        >
          Spelling
        </Text>
        Bee
      </Text>
    </Box>
  );
};

import {
  Flex,
  Box,
  Button,
  Text,
  Heading,
  useColorModeValue,
  Slide,
  SimpleGrid,
} from "@chakra-ui/react";
import { AnimatedTypingText } from "../comon/AnimatedTypingText";
import { MotionElement } from "../comon/MotionElement";
import { CreateGame } from "./CreateGame";
import { useState } from "react";

export const Hero = () => {
  const [isCreateGameVisible, setCreateGameVisible] = useState<boolean>(false);

  const handleStartNewGame = () => {
    setCreateGameVisible(true);
  };
  const handleCloseCreateGame = () => {
    setCreateGameVisible(false);
  };

  return (
    <Flex
      direction="column"
      align="center"
      textAlign="center"
      bg={useColorModeValue("blue.50", "gray.800")}
      py={10}
      px={5}
    >
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 1, xl: 2, "2xl": 2 }}
        spacing={5}
        borderRadius="lg"
        p={5}
      >
        <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Box
              width={{ base: "100%", sm: "85%" }}
              zIndex="2"
              marginLeft={{ base: "0", sm: "5%" }}
              marginTop="5%"
              textAlign={"center"}
            >
              <MotionElement type="fadeInX">
                <Heading fontSize="4xl" mt={9}>
                  Find out where your{" "}
                  <Text as="span" color="#F26B3A">
                    Memory IQ
                  </Text>{" "}
                  stands compared with others.
                </Heading>
              </MotionElement>
              <Box color={"gray.600"} textAlign={"left"}>
                <Text color={"gray.600"} mt={9}>
                  Ready to lay your way to a better memory?
                  <Text as={"span"} className="primary-color">
                     {" "}Start Today and get a  <Text as={"b"}> FREE </Text> certificate!
                  </Text>
                </Text>
              </Box>
              <Box textAlign={"left"}>
                <MotionElement type="fadeInX">
                  {!isCreateGameVisible && (
                    <SimpleGrid
                      columns={{ base: 1, md: 1, lg: 1, xl: 2, "2xl": 2 }}
                      spacing={5}
                      borderRadius="lg"
                      p={5}
                    >
                      <Button
                        data-testid="start-new-game"
                        colorScheme="blue"
                        variant="outline"
                        size="lg"
                        onClick={handleStartNewGame}
                      >
                        Start M-IQ with your team
                      </Button>
                      <Button
                        as={"a"}
                        href="https://thelastspellingbee.com/spell/137-f2cedca8-07b9-4fb0-838e-3dd513889844"
                        colorScheme="blue"
                        size="lg"
                      >
                        Test My Memory IQ Now!
                      </Button>
                    </SimpleGrid>
                  )}
                </MotionElement>
              </Box>
              {isCreateGameVisible && (
                <Slide
                  direction="right"
                  in={isCreateGameVisible}
                  style={{
                    zIndex: 10,
                    marginTop: -190,
                    position: "absolute",
                    width: 850,
                  }}
                >
                  <Box
                    mt={200}
                    ml={{ base: 550, md: 300 }}
                    width={{ base: 350, md: "100%" }}
                  >
                    <CreateGame
                      isOpen={isCreateGameVisible}
                      onClose={handleCloseCreateGame}
                    />
                  </Box>
                </Slide>
              )}
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
            </Box>
        </Box>
        <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: "3", sm: "0" }}
          >
            <Heading marginTop="1"></Heading>
            <Box
              marginTop="2"
              color={useColorModeValue("gray.700", "gray.200")}
              fontSize="lg"
            >
              <Text
                as={"span"}
                align={"left"}
                fontSize={{ base: 60, md: 60, lg: 60, xl: 70, "2xl": 70 }}
                className="textTheLastSpellingBee3D"
              >
                <AnimatedTypingText
                  text="A1-B2-C3-D4"
                  speed={0.3}
                ></AnimatedTypingText>
              </Text>

              <Box>
                <Text
                  as={"span"}
                  w={"full"}
                  marginTop={3}
                  color={"gray.600"}
                  align={"left"}
                >
                  <AnimatedTypingText text="Join the Memory Revolution, Where Fun Meets Cognitive Growth with"></AnimatedTypingText>

                  <Text
                    as={"span"}
                    color={"#F26B3A"}
                    fontWeight={600}
                    align={"left"}
                  >
                    {" "}
                    <AnimatedTypingText text="ThelastSpellingBee"></AnimatedTypingText>
                  </Text>
                </Text>
              </Box>
            </Box>
          </Box>
      </SimpleGrid>
      
    </Flex>
  );
};

import * as yup from 'yup';
import { InferType } from 'yup';

export const SignInValidationSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password  is required'),
});
export type SignIn= InferType<typeof SignInValidationSchema>;


export const SignUpValidationSchema = yup.object().shape({
  company_name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password  is required'),
});
export type SignUp = InferType<typeof SignUpValidationSchema>;


export const ForgetPasswordValidationSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
});
export type ForgetPassword = InferType<typeof ForgetPasswordValidationSchema>;


export const ResetPasswordValidationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  email: yup.string().required('email is required'),
  token: yup.string().required('token is required'),

});
export type ResetPasswordValidation = InferType<typeof ResetPasswordValidationSchema>;


export const ContactUsValidationSchema = yup.object().shape({
  subject: yup.string().required('Subject is required'),
  fullName: yup.string().required('Full Name is required'),
  email: yup.string().required('Email is required'),
  message: yup.string().required('Message is required'),
});
export type ContactUs = InferType<typeof ContactUsValidationSchema>;
import moment from "moment-timezone";

const convertToUserTimezone = (date: string) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment(date).tz(userTimezone);
};

export const dateToString = (date: string, isTime?: boolean) => {
  if (isTime) {
    return convertToUserTimezone(date).format("MMM d, yyyy hh:mm a");
  }
  return convertToUserTimezone(date).format("MMM D, YYYY");
};

export const n = (date: string) => {
  return convertToUserTimezone(date).format("MMM D, YYYY h:mm z ");
};

export const calculateDaysLeft = (targetDate: string) => {
  const today = convertToUserTimezone(new Date().toISOString()).startOf("day");
  const endDate = convertToUserTimezone(targetDate).startOf("day");
  return endDate.diff(today, "days");
};

import React, { FC } from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  Text,
  useToast,
  Box,
  Spinner,
  Stack,
  Heading,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';  
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { Game, CreateGameGameData } from "../../../redux/service/types";
import {
  categoryOPtions,
  questionTypeOptions,
  wordLengthOptions,
  numberOfQuestionsOptions,
  gameTimeOptions,
} from "../../home/types";
import { GameValidationSchema, GameDefaultValues } from "./GameValidationSchema";
import { CustomSelect } from "../../comon/CustomSelect";
import { updateGameAction } from "../../../redux/slice/user/manageGameSlice";

interface EditGameProps {
  game: Game;
}
export const EditGame: FC<EditGameProps> = ({
    game
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<CreateGameGameData>({ 
    resolver: yupResolver(GameValidationSchema("auth")),
    defaultValues: GameDefaultValues(game as CreateGameGameData),
  });
 
  const onSubmitHandler = async (data: any) => {
    try {
        await dispatch(updateGameAction(data));
        toast({
          title: "Game Updated.",
          description: "Game updated successfully",
          status: "success",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });
        navigate(`/`);
    } catch (error: any) {
        console.log(error.message);
    }
  };

  return (
    <>
      <Text as={"a"} href="#" onClick={onOpen}>
        <Text ml={1}>Edit Test</Text>
      </Text>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent p={3}>
          <ModalHeader>
            <Heading fontSize={20}>Edit  Game</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              as={"form"}
              mt={0}
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <Stack spacing={4}>
                <FormControl isRequired>
                    <FormLabel>M-IQ Name</FormLabel>
                    <Input
                        {...register("gameName")}
                        id="gameName"
                        placeholder="Game's Name"
                        isInvalid={!!errors.gameName}
                        required
                    />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Category</FormLabel>
                    <CustomSelect
                        register={register}
                        id="gameCategory"
                        placeholder="Select Game Category"
                        defaultValue={game.gameCategory}
                        options={categoryOPtions}
                    />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Game Level</FormLabel>
                    <CustomSelect
                        register={register}
                        id="questionType"
                        placeholder="Select Game Level"
                        defaultValue={game.questionType}
                        options={questionTypeOptions}
                    />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Word Length</FormLabel>
                    <CustomSelect
                    register={register}
                    id="wordLength"
                    placeholder="Select Word Length"
                    defaultValue={game.wordLength}
                    options={wordLengthOptions}
                />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Number of Questions</FormLabel>
                    <CustomSelect
                        register={register}
                        id="numberOfQuestions"
                        placeholder="Select Number of Questions"
                        defaultValue={game.numberOfQuestions}
                        options={numberOfQuestionsOptions}
                    />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Game Time</FormLabel>
                    <CustomSelect
                        register={register}
                        id="gameTime"
                        placeholder="Select Game Time"
                        defaultValue={game.gameTime}
                        options={gameTimeOptions}
                    />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Expiry Date</FormLabel>
                    <Input
                    type="date"
                        {...register("expiryDate")}
                        id="expiryDate"
                        required
                    />
                </FormControl>
              </Stack>
              <Button
                fontFamily={"heading"}
                mt={8}
                w={"full"}
                colorScheme="blue"
                type="submit"
              >
                {isSubmitting ? <Spinner /> : "Submit"}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
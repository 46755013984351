import { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { config } from "../../common/config";
import { usePlayGame } from "./use-play-game";
import { getCurrentGameScore } from "../../apps/spelling/game-play/helper";

export const useSocket = () => {
  const { playGame } = usePlayGame();
  const [playersScores, setPlayersScores] = useState<
    Record<string, { name: string; score: number }>
  >({});
  const [socket, setSocket] = useState<Socket | null>(null);

  const currentScore = getCurrentGameScore();

  useEffect(() => {
    // Initialize or reuse the socket
    if (!socket) {
      localStorage.removeItem("socketId");
    }
    const storedSocketId = localStorage.getItem("socketId");
    const newSocket = io(config.socketUrl, {
      query: { socketId: storedSocketId }, // Send existing ID if available
    });

    newSocket.on("connect", () => {
      // Save the socket ID to localStorage
      if (!storedSocketId || storedSocketId !== newSocket.id) {
        localStorage.setItem("socketId", newSocket.id ?? '');
      }

      // Emit joinGame only if it's a new connection
      if (!storedSocketId) {
        const playerName = playGame?.user?.user[0]?.userName || "Unknown Player";
        newSocket.emit("joinGame", { name: playerName });
      }

      console.log("Connected with socketId:", newSocket.id);
    });

    // Listen for real-time updates
    newSocket.on("updateScores", (scores) => {
      setPlayersScores(scores);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playGame]);

  const updateScore = () => {
    if (socket) {
      console.log("socket.id: ", socket.id);
      socket.emit("updateScore", { playerId: socket.id, score: currentScore });
    }
  };

  return { playersScores, updateScore };
};

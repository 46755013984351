import { FC } from "react";
import { Box, Button, Center, Link, Stack } from "@chakra-ui/react";
import { GameResult } from "../../../../redux/service/types";
import ContentCard from "../../../nav/ContentCard";
import { CertificateView } from "./CertificateView";

type ResultProps = {
  result: GameResult;
  isBackBtn?: boolean;
};

export const EducationResult: FC<ResultProps> = ({
  result,
  isBackBtn = false,
}) => {
  const totalQuestions = parseInt(result.numberOfQuestions, 10);
  const score = result.score;
  const percentage = ((score / (totalQuestions * 5)) * 100).toFixed(2);
  const correctAnswers = Math.floor(score / 5);
  const incorrectAnswers = totalQuestions - correctAnswers;

  const data = [
    { name: "Correct", value: correctAnswers },
    { name: "Incorrect", value: incorrectAnswers },
  ];

  const COLORS = ["#2f855a", "#c53030"];

  return (
    <ContentCard>
      <Box>
        {isBackBtn ? (
          <Box textAlign="left">
            <Link
              href={`/game-result/${result.gameId}`}
              color="#2b6cb0"
              fontSize={{ base: 14, sm: 17 }}
              me="10px"
            >
              <Button h={8} colorScheme="blue" variant="outline">
                Back
              </Button>
            </Link>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Center>
      <Stack direction={"column"}>
        <CertificateView
          result={result}
          percentage={percentage}
          correctAnswers={correctAnswers}
          totalQuestions={totalQuestions}
          data={data}
          COLORS={COLORS}
        />
        </Stack>
      </Center>
    </ContentCard>
  );
};

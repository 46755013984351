import { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Input,
  Stack,
  Button,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/hooks/useTypedSelector";
import { QuestionsCard } from "./QuestionsCard";
import { GameHeaderCard } from "./GameHeaderCard";
import { useNavigate } from "react-router-dom";
import { AnswerGame } from "../../../redux/service/types";
import { answerGameAction } from "../../../redux/slice/game/answerSlice";
import {
  checkAuth,
  getCurrentGameScore,
  validateNumberQuestion,
  validateQuestion,
} from "./helper";
import { useGameComplete } from "../../../redux/hooks/useGameComplete";
import { AnimatedBackground } from "./result-chart/AnimatedBackground";
import { useAnswerFeedback } from "../../../redux/hooks/useAnswerFeedback";
import ContentCard from "../../nav/ContentCard";
import { usePlayGame } from "../../../redux/hooks/use-play-game";
import { backgroundImages } from "./BgImages";
import { useSocket } from "../../../redux/hooks/use-socket";

export const SpellingGamePlay = () => {
  const { playGame } = usePlayGame();
  const { answerData } = useAppSelector((state) => state.answerGame);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [gameScore, setGameScore] = useState<number>(0);
  const [answerAlert, setAnswerAlert] = useState<any>();
  const { gameComplete } = useGameComplete(getCurrentGameScore());
  const { renderAnswer } = useAnswerFeedback();
  const { playersScores, updateScore } = useSocket();
  const [isHideLivePlayersScore, setIsHideLivePlayersScore] =
    useState<boolean>(false);

  const [bgImage] = useState(
    () => backgroundImages[Math.floor(Math.random() * backgroundImages.length)],
  );

  const [clickSnd] = useState(new Audio("/sounds/click.wav"));

  checkAuth(playGame);
  const [formData, setFormData] = useState({
    token: playGame.user.token,
    question: "",
    answer: "",
  });
  const questions = playGame.question;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    clickSnd.play();
    try {
      const currentQuestion = JSON.stringify(
        questions[currentQuestionIndex].question,
      );
      await dispatch(
        answerGameAction({
          ...formData,
          question: validateQuestion(currentQuestion),
        } as AnswerGame),
      );
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setGameScore(answerData?.score + gameScore);
      setAnswerAlert(renderAnswer());

      setShowFeedback(true);
      setTimeout(() => {
        setShowFeedback(false);
      }, 2000);
      setFormData({
        ...formData,
        answer: "",
      });
      updateScore();
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const savedGameState = JSON.parse(
      localStorage.getItem("gameState") || "{}",
    );
    if (
      savedGameState &&
      savedGameState.currentQuestionIndex < (questions?.length || 0)
    ) {
      setCurrentQuestionIndex(savedGameState.currentQuestionIndex);
      setGameScore(savedGameState.gameScore);
    }
  }, [questions]);

  useEffect(() => {
    const gameStateToSave = { currentQuestionIndex, gameScore };
    localStorage.setItem("gameState", JSON.stringify(gameStateToSave));
  }, [currentQuestionIndex, gameScore]);

  useEffect(() => {
    if (currentQuestionIndex === questions?.length) {
      gameComplete();
      navigate("/spelling-result");
    }
  }, [currentQuestionIndex, questions?.length, gameComplete, navigate]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value.toUpperCase(),
    });
  };

  const onGameOver = async () => {
    await gameComplete();
    navigate("/spelling-result");
  };
  const renderPlayerScores = () =>
    Object.entries(playersScores).map(([id, data]) => (
      <Box
        key={id}
        p={2}
        color="#FFF"
        borderRadius="md"
        bg="rgba(0, 0, 0, 0.5)"
        mt={2}
      >
        <Text textAlign={"left"} textTransform={"capitalize"}>
          {data.name} : <strong> {data.score}</strong>
        </Text>
      </Box>
    ));

  return (
    <ContentCard
      position="relative"
      bgImage={`url('${bgImage}')`}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      width={"100%"}
      borderRadius={0}
      p={0}
    >
      <AnimatedBackground />
      <GameHeaderCard
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions?.length}
        score={gameScore}
        gameTime={playGame.gameTime}
        gameName={playGame.gameName}
        onGameOver={onGameOver}
      />

      <Flex
        align="flex-start"
        width="100%"
        direction={{ base: "column", md: "row" }}
        gap={6}
        p={4}
      >
        <Stack
          width={{ base: "100%", md: "70%" }}
          p={4}
          borderRadius="md"
          bg="transparent"
          zIndex={1000}
        >
          {currentQuestionIndex < questions?.length && (
            <Box flex={1}>
              <QuestionsCard
                instructions={playGame.instructions}
                question={validateNumberQuestion(
                  questions[currentQuestionIndex].question,
                )}
              />
            </Box>
          )}
          <Box as="form" mt={3} onSubmit={onSubmit}>
            <FormControl id="Answer" w="full">
              <Input
                type="text"
                id="answer"
                onChange={onChange}
                placeholder="Answer"
                value={formData.answer}
                fontSize={34}
                h={50}
                padding={2}
                bg="#FFF"
              />
            </FormControl>
            <Button type="submit" colorScheme="blue" w="full" size="lg" mt={3}>
              Submit
            </Button>
            <Text textAlign="center" mt={0}>
              {showFeedback && answerAlert}
            </Text>
          </Box>
        </Stack>
        {/* Right Column */}
        <Box
          width={{ base: "100%", md: "30%" }}
          border="1px #FFF solid"
          p={4}
          mt={3}
          borderRadius="md"
        >
          <Button
            float={"right"}
            onClick={() => setIsHideLivePlayersScore(!isHideLivePlayersScore)}
          >
            {isHideLivePlayersScore ? "Show" : "Hide"} Score
          </Button>
          <Heading color={"#FFF"} fontSize={17} mb={3}>
            Live Player Scores
          </Heading>
          {!isHideLivePlayersScore && renderPlayerScores()}
        </Box>
      </Flex>
    </ContentCard>
  );
};

import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import {
  categoryOPtions,
  questionTypeOptions,
  wordLengthOptions,
  numberOfQuestionsOptions,
  gameTimeOptions,
} from "../home/types";
import { CustomSelect } from "./CustomSelect";
import { GameLevelExplanation } from "./GameLevelExplanation";


interface CreateGameFormProps {
  register: any;
  errors: any;
  control?: any,
  type: "auth" | "unAuth";
}

export const CreateGameForm: React.FC<CreateGameFormProps> = ({
  register,
  errors,
  control,
  type,
}) => {
  return (
    <Stack spacing={4}>
      <FormControl isRequired>
        <FormLabel>M-IQ Name</FormLabel>
        <Input
          {...register("gameName")}
          id="gameName"
          placeholder="M-IQ Name"
          isInvalid={!!errors.gameName}
          required
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Category</FormLabel>
        <CustomSelect
          register={register}
          id="gameCategory"
          placeholder="Select M-IQ Category"
          options={categoryOPtions}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>
          M-IQ Level
          <Tooltip
        label={
          
           <GameLevelExplanation />
           
        }
        placement="right"
        >
            <Text 
                as={"span"} 
                ml={2} 
                fontSize={14} 
                cursor={"pointer"}
                color={"#2b6cb0"}>
              Learn more?
            </Text>
          </Tooltip>
        </FormLabel>
        <CustomSelect
          register={register}
          id="questionType"
          placeholder="Select Game Level"
          options={questionTypeOptions}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Word Length</FormLabel>
        <CustomSelect
          register={register}
          id="wordLength"
          placeholder="Select Word Length"
          options={wordLengthOptions}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Number Of Questions</FormLabel>
        <CustomSelect
          register={register}
          selectType={"slider"}
          control={control}
          id="numberOfQuestions"
          placeholder="Select Number of Questions"
          options={numberOfQuestionsOptions}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>M-IQ Time (Minutes)</FormLabel>
        <CustomSelect
          register={register}
          selectType={"slider"}
          control={control}
          id="gameTime"
          placeholder="Select M-IQ Time"
          options={gameTimeOptions}
        />
      </FormControl>
      { type === "auth" ?
      <FormControl isRequired>
        <FormLabel>Expiry Date</FormLabel>
        <Input
          type="date"
          {...register("expiryDate")}
          id="expiryDate"
          placeholder="Expiry Date"
          isInvalid={!!errors.expiryDate}
          required
        />
      
      </FormControl>
        : "" }
    </Stack>
  );
};

import { Flex, Box, SimpleGrid, Heading, Spinner } from "@chakra-ui/react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux/hooks/useTypedSelector";
import { GameCard } from "./GameCard";
import { AnimatedTypingText } from "../comon/AnimatedTypingText";
import { CreateNewGame } from "./action/CreateNewGame";
import { useEffect } from "react";
import { getGamesAction } from "../../redux/slice/user/manageGameSlice";
import { Game } from "../../redux/service/types";
import ContentCard from "../nav/ContentCard";

export const Dashboard = () => {
  const { data } = useAppSelector((state) => state.auth);
  const { games, isLoading } = useAppSelector((state) => state.getGames);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGamesAction(data.company.user.id as number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  const orderedData = (games?.result || [])
    .slice()
    .sort((a: { id: number }, b: { id: number }) => b.id - a.id);
  return (
    <ContentCard>
        <Box borderRadius="15px" px="0px" display="flex">
          <Heading mb={5} textAlign={"center"}>
            <AnimatedTypingText text="M-IQs" speed={0.2} />
          </Heading>
        </Box>
        <Flex justify="flex-end">
          <CreateNewGame companyId={data.company.user.id as number} />
        </Flex>
        <SimpleGrid
          columns={{ base: 1, md: 1, lg: 2, xl: 3, "2xl": 3 }}
          spacing={3}
          mt={5}
        >
          {orderedData &&
            orderedData.map((game: Game, index: any) => (
              <GameCard game={game} />
            ))}
        </SimpleGrid>
    </ContentCard>
  );
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import gameService from '../../service/game/gameService';
import { CreateGameGameData } from '../../service/types';

interface CreateNewGameState {
  data?: CreateGameGameData | null;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: any;
}

const initialState: CreateNewGameState = {
  data: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

const handleRejected = (state: CreateNewGameState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.message = action.payload;
  state.data = null;
};

export const createNewGameAction = createAsyncThunk('auth/createNewGame', async (data: CreateGameGameData, thunkAPI) => {
  try {
    return await gameService.createNewGame(data);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});


export const startNewGameSlice = createSlice({
  name: 'newGame',
  initialState,
  reducers: {
    resetNewGameAction: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewGameAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewGameAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload.data; 
      })
      .addCase(createNewGameAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
  },
});

export const { resetNewGameAction } = startNewGameSlice.actions;
export default startNewGameSlice.reducer;

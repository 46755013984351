import { AnyAction, Reducer, combineReducers } from "@reduxjs/toolkit";
import signUpReducer from "./auth/authSlice";
import newGameReducer from "./game/startNewGameSlice";
import gamePlayReducer from "./game/spellingSlice";
import answerReducer from "./game/answerSlice";
import gameCompleteReducer from "./game/gameCompletSlice";
import getGamesReducer from "./user/manageGameSlice";
import gameResultReducer from "./user/gameResultSlice";
import ApiKeyReducer from "./api-key/recaptchaSlice";
import globalScoreReducer from "./game/globalScoreSlice";
import iQScoreReducer from "./game/iQScoreSlice";
import contactUsReducer from "./contact-us/contactUsSlice";




const rootReducer = combineReducers({
  auth: signUpReducer,
  newGame: newGameReducer,
  gamePlay: gamePlayReducer,
  answerGame: answerReducer,
  gameComplete: gameCompleteReducer,
  getGames: getGamesReducer,
  gameResult: gameResultReducer,
  ApiKey: ApiKeyReducer,
  globalScore: globalScoreReducer,
  iqScore: iQScoreReducer,
  contactUs: contactUsReducer,
});

export const resetAllReducers = () => ({
  type: "RESET_ALL_REDUCERS",
});

const rootReducerWithReset: Reducer<any, AnyAction> = (state: any, action: AnyAction) => {
    if (action.type === "RESET_ALL_REDUCERS") {
      state = undefined;
    }
    return rootReducer(state, action);
  };

export default rootReducerWithReset;

import { useEffect, useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useToast,
  Heading,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useAppDispatch,
} from "../../redux/hooks/useTypedSelector";
import {
  ContactUs,
  ContactUsValidationSchema,
} from "../auth/schema";
import { contactUsAction } from "../../redux/slice/contact-us/contactUsSlice";
import { TheLastSpellingBeeReCaptcha } from "the-last-spelling-bee-re-captcha";

export const GlobalContactUs = () => {
  const [isCaptchaVerify, setIsCaptchaVerify] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(ContactUsValidationSchema),
    defaultValues: {
      subject: "",
      fullName: "",
      email: "",
      message: "",
    },
  });

  useEffect(() => {
    setIsCaptchaVerify(isCaptchaVerify);
  }, [isCaptchaVerify]);


  const onSubmitHandler = async (data: ContactUs) => {
    try {
      await dispatch(contactUsAction(data));
      setIsCaptchaVerify(true);
      toast({
        title: "Message sent successful.",
        description: "Success",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      reset();
    } catch (error: any) {
      console.log(error.message);
      toast({
        title: "Error",
        description: "Error sending your message..",
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };




  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={"gray.50"}
      width={"100%"}
    >
      <Stack
        spacing={8}
        mx={"auto"}
        py={12}
        px={6}
        mt={9}
        width={{ base: "100%", md: "50%" }}
      >
        <Box rounded={"lg"} bg={"#FFF"} boxShadow={"lg"} p={8}>
            <Box as={"form"} mt={0} onSubmit={handleSubmit(onSubmitHandler)}>
              <Flex direction="column" w="100%" background="transparent">
                  <Heading fontSize="32px" mb={5}>
                    Contact Us
                  </Heading>
                <FormControl>
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Subject
                  </FormLabel>
                  <Select
                    placeholder="Select Subject"
                    {...register("subject")}
                    required
                  >
                    <option value="Report a bug">Report Bug</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Others">Others</option>
                  </Select>
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Full Name
                  </FormLabel>
                  <Input
                    type="text"
                    {...register("fullName")}
                    placeholder="Full Name"
                    isInvalid={!!errors.fullName}
                    required
                    mb={4}
                  />
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Email
                  </FormLabel>
                  <Input
                    type="email"
                    {...register("email")}
                    placeholder="Your email address"
                    id="email"
                    isInvalid={!!errors.email}
                    required
                    mb={4}
                  />
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Message
                  </FormLabel>
                  <Textarea
                    {...register("message")}
                    placeholder="Message"
                    required
                    border={"1px #000 solid"}
                  />
                  <TheLastSpellingBeeReCaptcha
                    reCaptchaKey={"30815c93-03af-4a1b-80f9-79015de0ded8"}
                    questionType="RANDOM"
                    onVerifyCaptcha={(result: any) => {
                      setIsCaptchaVerify(!result);
                    }}
                  />
                  {!isCaptchaVerify ? 
                  <Button
                    type="submit"
                    w="100%"
                    h="45"
                    mb="20px"
                    colorScheme={"blue"}
                    isLoading={isSubmitting}
                  >
                    Submit
                  </Button>
                  : "" }
                </FormControl>
              </Flex>
            </Box>
        </Box>
      </Stack>
    </Flex>
  );
};

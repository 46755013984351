import {
  Flex,
  Text,
  VStack,
  SimpleGrid,
  Heading,
  Spinner,
  Button,
  ButtonGroup,
  Img,
} from "@chakra-ui/react";
import { GameResult } from "../../../redux/service/types";
import { FC } from "react";
import { useLatestResults } from "../../../redux/hooks/use-latest-results";
import ReactCountryFlag from "react-country-flag";
import { calGameScore } from "./helper";
import { calculateTimeTaken } from "./SpellingResult";
import ContentCard from "../../nav/ContentCard";

type DisplayLatestResultsProps = {
  paginatePerPage?: number;
};

export const DisplayLatestResults: FC<DisplayLatestResultsProps> = ({
  paginatePerPage,
}) => {
  const { isLoading, paginate, currentItems, results, itemsPerPage } =
    useLatestResults(paginatePerPage);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ContentCard>
      <Heading textAlign="center" mb={5} mt={20}>
        Latest IQ Results
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 1, xl: 2, "2xl": 2 }}
        spacing={5}
        borderRadius="lg"
        p={{ base: 0, md: 0, lg: 0, xl: 5, "2xl": 5 }}
        width={{
          base: "100%",
          md: "100%",
          lg: "100%",
          xl: "70%",
          "2xl": "70%",
        }}
      >
        {currentItems &&
          currentItems.map((game: GameResult, index: any) => (
            <Flex
              key={index}
              align="center"
              justify="space-between"
              bg={"white"}
              borderRadius={"md"}
              boxShadow={"lg"}
              p={3}
            >
              <Flex align="center">
                <Text mr={3} fontSize="2xl">
                  <ReactCountryFlag countryCode={game.country ?? "CA"} />
                </Text>
                <VStack align="start" spacing={0}>
                  <Text fontWeight="medium" textTransform={"capitalize"}>{game.userName}</Text>
                  <Text color="gray.500" fontSize="sm">
                    {calculateTimeTaken(game)}
                  </Text>
                </VStack>
              </Flex>
              <Flex fontWeight="bold" fontSize="lg" color={"blue.600"}>
                <Text
                  as={"span"}
                  mr={
                    calGameScore(game.numberOfQuestions, game.score) >= 85
                      ? -7
                      : 0
                  }
                  pr={2}
                >
                  M-IQ {calGameScore(game.numberOfQuestions, game.score)}{" "}
                </Text>
                {calGameScore(game.numberOfQuestions, game.score) >= 85 && (
                  <Img src="images/crown.svg" mt={-9} />
                )}
              </Flex>
            </Flex>
          ))}
      </SimpleGrid>

      <Flex justify="center" mt={4}>
        <ButtonGroup size="sm" isAttached variant="outline">
          {Array.from({
            length: Math.ceil(results.length / itemsPerPage),
          }).map((_, index) => (
            <Button
              key={index}
              onClick={() => paginate(index + 1)}
              cursor="pointer"
              colorScheme="blue"
              size="lg"
            >
              {index + 1}
            </Button>
          ))}
        </ButtonGroup>
      </Flex>
    </ContentCard>
  );
};

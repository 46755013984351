import { FC, useState, useEffect } from "react";
import { Stack, SimpleGrid, Box, Text, Flex, Button } from "@chakra-ui/react";
import { GamePlayInstruction } from "./GamePlayInstruction";
import { GameTime } from "./GameTime";
import "./animation.css";
import { EndGameModal } from "./EndGameModal";
import { usePlayGame } from "../../../redux/hooks/use-play-game";

type GameHeaderCardProps = {
  currentQuestionIndex: number;
  totalQuestions: number;
  score?: number;
  gameTime: number;
  gameName?: string;
  onGameOver?: (gameCompleteTime?: string) => void;
};

export const GameHeaderCard: FC<GameHeaderCardProps> = ({
  currentQuestionIndex,
  totalQuestions,
  score,
  gameTime,
  gameName,
  onGameOver,
}) => {
  const { playGame } = usePlayGame();

  const [isFlashing, setIsFlashing] = useState(false);
  const [prevScore, setPrevScore] = useState(score);

  const onTimerEnd = (gameCompleteTime: string) => {
    if (onGameOver) {
      onGameOver(gameCompleteTime);
    }
  };

  if (!localStorage.getItem("iendmygame")) {
    localStorage.setItem("iendmygame", btoa("0"));
  }
  const gameScoreString = atob(localStorage.getItem("iendmygame") ?? "0");
  const gameScore = parseInt(gameScoreString ?? "0");

  // Trigger flash animation when the score changes
  useEffect(() => {
    if (score !== prevScore) {
      setIsFlashing(true);
      setTimeout(() => setIsFlashing(false), 500); // Flash duration
      setPrevScore(score);
    }
  }, [score, prevScore]);

  return (
    <Stack p={0} m={4} w="full" spacing={6}>
      <Box textAlign="center">
        <Box
          fontSize="lg"
          fontWeight="bold"
          color="#FFF"
          textTransform={"capitalize"}
        >
          Hi {playGame?.user?.user[0]?.userName || "Player"} | Game{" "}
          {gameName || "Unknown"} |
          <EndGameModal />
        </Box>
      </Box>

      {/* Game Details */}
      <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} spacing={6}>
        <GamePlayInstruction />
        <GameTime
          initialMinuetsBySeconds={gameTime * 60}
          onTimerEnd={onTimerEnd}
        />
        <Button
          size="lg"
          cursor={"default"}
          colorScheme="blue"
          variant="outline"
          bg={"#FFF"}
          w={200}
          className={isFlashing ? "flash" : ""}
        >
          <Text fontSize="lg" fontWeight="bold">
            Score: {gameScore > 0 ? gameScore : 0}
          </Text>
        </Button>
      </SimpleGrid>

      {/* Score and Progress */}
      <Flex
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
        alignItems="center"
        gap={4}
      >
        <Text fontSize="2xl" fontWeight="bold" color={"#FFF"}>
          Question{" "}
          <Text
            as={"strong"}
            bg={"#FFF"}
            color={"#000"}
            borderRadius={"full"}
            p={1}
            mr={2}
            ml={2}
          >
            {" "}
            {currentQuestionIndex + 1}{" "}
          </Text>
          of
          <Text
            as={"strong"}
            bg={"#FFF"}
            color={"#000"}
            borderRadius={"full"}
            p={1}
            ml={2}
          >
            {totalQuestions}
          </Text>
        </Text>
      </Flex>
    </Stack>
  );
};

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
} from "@chakra-ui/react";
import { GameResult } from "../../../redux/service/types";
import { CertificateView } from "../game-play/result-chart/CertificateView";


type CertificateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  gameResult: GameResult;
};

export const CertificateModal: React.FC<CertificateModalProps> = ({
  isOpen,
  onClose,
  gameResult,
}) => {
  const totalQuestions = parseInt(gameResult?.numberOfQuestions ?? "", 10);
  const score = gameResult?.score ?? 0;
  const percentage = ((score / (totalQuestions * 5)) * 100).toFixed(2);
  const correctAnswers = Math.floor(score / 5);
  const incorrectAnswers = totalQuestions - correctAnswers;

  const data = [
    { name: "Correct", value: correctAnswers },
    { name: "Incorrect", value: incorrectAnswers },
  ];
  const COLORS = ["#2f855a", "#c53030"];

  if (!gameResult) {
    <Spinner />;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={"#2b6cb0"}>{gameResult?.userName}'s Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CertificateView
            result={gameResult ?? ({} as GameResult)}
            percentage={percentage}
            correctAnswers={correctAnswers}
            totalQuestions={totalQuestions}
            data={data}
            COLORS={COLORS}
          />
          
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

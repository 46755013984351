import { FC, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Card,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Link,
} from "@chakra-ui/react";
import { ShareLinks } from "./ShareLinks";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Game } from "../../redux/service/types";
import dateFormat from "dateformat";
import { EditGame } from "./action/EditGame";
import { DeleteGame } from "./action/DeleteGame";
import { getCategory, getExDate, getLevel, isExpired } from "../spelling/game-play/helper";
interface IGameCard {
  game: Game;
}
export const GameCard: FC<IGameCard> = ({ game }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

 
  return (
    <Card p="16px" my={{ base: "24px", xl: "0px" }} boxShadow={"xl"} minH={390}>
      <Flex justify="flex-end">
        <Menu>
          <MenuButton onClick={toggleMenu} as={Box} cursor="pointer">
            <Text fontWeight={"bold"} fontSize={24}>...</Text>
          </MenuButton>
          <MenuList minW="120px">
            <MenuItem onClick={closeMenu}>
              <EditGame game={game} />
            </MenuItem>
            <MenuItem onClick={closeMenu}>
              <DeleteGame game={game} />
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Heading p="12px 5px">
        <Text fontSize="lg" color={"#2b6cb0"} fontWeight="bold">
          {game.gameName}
        </Text>
      </Heading>
      <Box px="5px">
        <Flex direction="column" textAlign={"left"}>
          <Box mb="18px">
            <Box
              fontSize={{ base: 14, sm: 15 }}
              color={"gray.700"}
              fontWeight="bold"
              me="10px"
            >
              Level Type:{" "}
              <Text
                as={"span"}
                 fontSize={{ base: 14, sm: 15 }}
                color="gray.500"
                fontWeight="400"
                textTransform={"capitalize"}
              >
                {getLevel(game.questionType)}
              </Text>
            </Box>
          </Box>
          <Box mb="18px">
              <Box
                 fontSize={{ base: 14, sm: 15 }}
                color={"gray.700"}
                fontWeight="bold"
                me="10px"
              >
                Category:{" "}
                <Text
                  as={"span"}
                   fontSize={{ base: 14, sm: 15 }}
                  color="gray.500"
                  fontWeight="400"
                >{getCategory(game.gameCategory)}
                </Text>
              </Box>
            </Box>
          <SimpleGrid columns={{ base: 1, md: 2 }}>
            
            <Box mb="18px">
              <Text
                 fontSize={{ base: 14, sm: 15 }}
                color={"gray.700"}
                fontWeight="bold"
                me="10px"
              >
                Word Length:{" "}
                <Text
                  as={"span"}
                   fontSize={{ base: 14, sm: 15 }}
                  color="gray.500"
                  fontWeight="400"
                >
                  {game.wordLength}
                </Text>
              </Text>
            </Box>
            <Box mb="18px">
              <Box
                 fontSize={{ base: 14, sm: 15 }}
                color={"gray.700"}
                fontWeight="bold"
                me="10px"
              >
                Questions:{" "}
                <Text
                  as={"span"}
                   fontSize={{ base: 14, sm: 15 }}
                  color="gray.500"
                  fontWeight="400"
                >
                  {game.numberOfQuestions}
                </Text>
              </Box>
            </Box>
            <Box mb="18px">
              <Box
                 fontSize={{ base: 14, sm: 15 }}
                color={"gray.700"}
                fontWeight="bold"
                me="10px"
              >
                M-IQ Time:{" "}
                <Text
                  as={"span"}
                   fontSize={{ base: 14, sm: 15 }}
                  color="gray.500"
                  fontWeight="400"
                >
                  {game.gameTime} Min
                </Text>
              </Box>
            </Box>
            <Box mb="18px">
              <Box
                 fontSize={{ base: 14, sm: 15 }}
                color={"gray.700"}
                fontWeight="bold"
                me="10px"
              >
                Users Attempted:{" "}
                <Text
                  as={"span"}
                   fontSize={{ base: 14, sm: 15 }}
                  color="gray.500"
                  fontWeight="400"
                >
                  {game.scoreCount}
                </Text>
              </Box>
            </Box>
            {/* <Box mb="18px"></Box> */}
            <Box mb="18px">
              <Box
                 fontSize={{ base: 14, sm: 15 }}
                color="gray.500"
                fontWeight="400"
              >
                {game.scoreCount < 1 ? (
                  <Text color="#2b6cb0">No result yet..</Text>
                ) : (
                  <Link
                    href={`game-result/${game.id}`}
                    color="#2b6cb0"
                     fontSize={{ base: 14, sm: 15 }}
                    me="10px"
                  >
                    <Button
                      h={6}
                      rightIcon={<ArrowForwardIcon />}
                      colorScheme="blue"
                      variant="outline"
                    >
                      Result
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>
          </SimpleGrid>
          <Box mt={7}>
            <Box
              fontSize={{ base: 14, sm: 15 }}
              color={"gray.700"}
              fontWeight="bold"
              me="10px"
              mb={3}
            >
              Expiry date:{" "}
              <Text
                as={"span"}
                 fontSize={{ base: 14, sm: 15 }}
                color="gray.500"
                fontWeight="400"
              >
                {dateFormat(
                  getExDate(game.expiryDate ?? ""),
                  "ddd, mmmm dS, yyyy",
                )}
              </Text>
            </Box>
              {isExpired(new Date(game.expiryDate ?? "")) ? (
                <Text color={"red.600"}>
                  Test expired
                </Text>
              ) : (
                <ShareLinks id={game.id} handle={game.handle} />
              )}
          </Box>
        </Flex>
      </Box>
    </Card>
  );
};

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
} from "@chakra-ui/react";
import { GameResult } from "../../../../redux/service/types";
import { CertificateView } from "./CertificateView";

export type PlayerResultProps = {
  name: string;
  value: number;
  correct: number;
  incorrect: number;
  result: GameResult;
};

type PlayerDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  player: PlayerResultProps | undefined;
};

export const PlayerDetailsModal: React.FC<PlayerDetailsModalProps> = ({
  isOpen,
  onClose,
  player,
}) => {
  const result = player?.result;
  const totalQuestions = parseInt(result?.numberOfQuestions ?? "", 10);
  const score = result?.score ?? 0;
  const percentage = ((score / (totalQuestions * 5)) * 100).toFixed(2);
  const correctAnswers = Math.floor(score / 5);
  const incorrectAnswers = totalQuestions - correctAnswers;

  const data = [
    { name: "Correct", value: correctAnswers },
    { name: "Incorrect", value: incorrectAnswers },
  ];
  const COLORS = ["#2f855a", "#c53030"];

  if (!result) {
    <Spinner />;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={"#2b6cb0"}>{player?.name}'s Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CertificateView
            result={result ?? ({} as GameResult)}
            percentage={percentage}
            correctAnswers={correctAnswers}
            totalQuestions={totalQuestions}
            data={data}
            COLORS={COLORS}
          />
          
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

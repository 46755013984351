import { Flex, Stack } from "@chakra-ui/react";
import { EducationResult } from "./result-chart/EducationResult";
import { TeamBondingResult } from "./result-chart/TeamBondingResult";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/hooks/useTypedSelector";
import { useEffect } from "react";
import { resetGamePlay } from "../../../redux/slice/game/spellingSlice";
import { resetNewGameAction } from "../../../redux/slice/game/startNewGameSlice";
import { GameResult } from "../../../redux/service/types";
import { getGameCategory } from "./helper";

export const calculateTimeTaken = (result: GameResult | undefined) => {
  if(!result){
    return 'Incomplete Game';
  }

  const timeCompleted = JSON.parse(result.timeCompleted);
  const gameTime = parseInt(result.gameTime, 10);
  if(!timeCompleted.gameCompleteTime){
    return 'Incomplete Game';
  }

  const gameCompleteTimeArray = timeCompleted.gameCompleteTime.split(":");
  const hours = parseInt(gameCompleteTimeArray[0], 10);
  const minutes = parseInt(gameCompleteTimeArray[1], 10);
  const seconds = parseInt(gameCompleteTimeArray[2], 10);

  const timeTakenMinutes = gameTime - hours * 60 - minutes;
  const timeTakenSeconds = seconds;

  return `${timeTakenMinutes} min ${timeTakenSeconds} sec of ${gameTime} minutes`;
};
export const SpellingResult = () => {
  const { resultData } = useAppSelector(
    (state: { gameComplete: any }) => state.gameComplete,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetNewGameAction());
    dispatch(resetGamePlay());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const decodedResult = atob(localStorage.getItem("thelastspreult") ?? "[]");
  const userResult = resultData.data ? resultData.data : JSON.parse(decodedResult);
  
  if (!userResult[0].id) {
    window.location.href = "/";
  }
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} width={"full"} py={12} px={6} bg={"#FFF"}>
        {getGameCategory(userResult[0]) === "teamBonding" ? (
          <TeamBondingResult result={userResult} />
        ) : (
          <EducationResult result={userResult[0] as GameResult} />
        )}
      </Stack>
    </Flex>
  );
};

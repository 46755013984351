import {
  Flex,
  Stack,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { RecaptchaHero } from "../recaptcha/RecaptchaHero";
import { VideoAboutTheLastSpellingBee } from "../recaptcha/VideoAboutTheLastSpellingBee";

export const AboutSection = () => {
  return (
    <Flex
      direction="column"
      align="center"
      py={10}
    >
      <Stack
        bg={useColorModeValue("white", "gray.800")}
        // boxShadow={"lg"}
        p={8}
        rounded={"xl"}
        align={"center"}
        pos={"relative"}
        width={{ base: "100%", md: "100%", lg: "100%", xl: "70%", "2xl": "70%" }}
      >
        <VideoAboutTheLastSpellingBee
          videoUrl={"https://www.youtube.com/watch?v=FFc2Pu7EcOg&t=23s"}
        />
      </Stack>
      <Flex justify="center" gap={9} mt={9}>
        <VStack
          bg={useColorModeValue("gray.100", "gray.700")}
         p={{ base: 0, md: 0, lg: 0, xl: 20, "2xl": 20 }}
        >
          <RecaptchaHero />
        </VStack>
      </Flex>
    </Flex>
  );
};

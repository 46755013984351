import { clearStorage } from "../../apps/spelling/game-play/helper";
import { gameCompleteAction } from "../slice/game/gameCompletSlice";
import { usePlayGame } from "./use-play-game";
import { useAppDispatch, useAppSelector } from "./useTypedSelector";

export const useGameComplete = (gameScore: number) => {
  const { playGame } = usePlayGame();
    const { isSuccess } = useAppSelector((state) => state.gameComplete);

    const dispatch = useAppDispatch();

    const gameComplete = async (gameCompleteTime?: string) => {
      const finishGameData = {
        token: playGame.user.token,
        gameId: playGame.id,
        id: playGame.user.user[0].id.toString(),
        gameCategory: playGame.gameCategory,
        score: gameScore,
        timeCompleted: {
          gameTime: playGame.gameTime,
          gameCompleteTime: gameCompleteTime ?? localStorage.getItem("timeOnComplete"),
        },
      };

      await dispatch(gameCompleteAction(finishGameData));
      clearStorage();

      if(isSuccess){
        window.location.href = '/spelling-result';
      }
      
    };

  return { gameComplete };
};
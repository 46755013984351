import { FC } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";
import {
  Box,
  Heading,
  Text,
  Divider,
  VStack,
  Button,
  Flex,
} from "@chakra-ui/react";
import { GameResult } from "../../../../redux/service/types";
import { calculateTimeTaken } from "../SpellingResult";
import { dateToString } from "../../../../common/utils/date-time";
import { addSpaceAfterEachWord } from "../../../../common/utils/helperFuntions";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

type CertificateViewProps = {
  result: GameResult;
  percentage: string;
  correctAnswers: number;
  totalQuestions: number;
  data: { name: string; value: number }[];
  COLORS: string[];
};

export const CertificateView: FC<CertificateViewProps> = ({
  result,
  percentage,
  correctAnswers,
  totalQuestions,
  data,
  COLORS,
}) => {
//   const [isDownloading, setIsDownloading] = useState<boolean>(false);
//   const handlePrint = () => {
//     window.print();
//   };

  const handleSavePDF = async () => {
    // setIsDownloading(true); // Trigger the padding change

    const certificateElement = document.getElementById("certificate-content");
    if (!certificateElement) return;

    const canvas = await html2canvas(certificateElement, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("certificate.pdf");

    // setIsDownloading(false); 
  };

  return (
    <>
     
     <Flex justify="flex-end" mb={2}>
        {/* <Button colorScheme="blue" size="sm" onClick={handlePrint} mr={2}>
          Print
        </Button> */}
        <Button
          colorScheme="teal"
          size="sm"
          onClick={handleSavePDF}
          cursor={"pointer"}
        >
          Download Certificate
        </Button>
      </Flex>
      <Box
        borderWidth="2px"
        borderColor="#F26B3A"
        borderStyle="solid"
        borderRadius="md"
        p={10}
        maxW="800px"
        boxShadow="lg"
        position="relative"
        id="certificate-content"
        style={{ pageBreakInside: "avoid" }}
      >
        {/* Border Design */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          borderWidth="4px"
          borderStyle="double"
          borderColor="#F26B3A"
          borderRadius="md"
          pointerEvents="none"
        />
        <VStack spacing={6} align="center">
          {/* <BrandLogo /> */}
          <Text
            fontSize={19}
            fontWeight={400}
            color={"#000"}
            ml={{ base: 0, sm: 1 }}
          >
            TheLast
            <Text
              as={"span"}
              color={"#F26B3A"}
            //   borderTop={"2px #F26B3A solid"}
            //   borderBottom={"2px #F26B3A solid"}
              borderRadius={7}
            //   pt={isDownloading ? 3 : 2}
            //   pb={isDownloading ? 3 : 2}
            >
              Spelling
            </Text>
            Bee
          </Text>
          <Heading as="h1" size="lg" textTransform="uppercase" color="gray.700">
            Certificate of Achievement
          </Heading>
          <Divider borderColor="#888" />
          <Text fontSize="2xl" fontWeight="bold" color="gray.800">
            {`This certifies that`}
          </Text>
          <Text
            fontSize="4xl"
            fontWeight="bold"
            color="blue.600"
            textTransform="capitalize"
          >
            {result.userName}
          </Text>
          <Text fontSize="lg" color="gray.700">
            has successfully completed the <Text as="b">Memory IQ</Text> Test
            with an impressive score of
          </Text>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={100}
                fill="#8884d8"
                label={({ value, name }) => `${name}: ${value}`}
              >
                {data.map((entry, index) => (
                  <Cell key={index} fill={COLORS[index % COLORS.length]} />
                ))}
                <Label
                  content={() => (
                    <text
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      dominantBaseline="central"
                      fill="#000"
                      fontSize="50"
                      fontWeight="600"
                    >
                      {parseInt(percentage)}%
                    </text>
                  )}
                  position="center"
                />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <Text fontSize="lg" color="gray.600">
            Answered <Text as="b">{correctAnswers} </Text> of{" "}
            <Text as="b">{totalQuestions}</Text> questions correctly
          </Text>
          <Box textAlign="left">
            <Text fontSize="lg" color="gray.600">
              <Text as="span" fontWeight={600}>
                Time Taken:
              </Text>{" "}
              {calculateTimeTaken(result)}
            </Text>
            <Text fontSize="lg" color="gray.600">
              <Text as="span" fontWeight={600}>
                Test Type:
              </Text>
              <Text as="span" textTransform="capitalize">
                {addSpaceAfterEachWord(result.gameCategory)}
              </Text>
            </Text>
          </Box>
          <Divider borderColor="#888" />
          <VStack spacing={1} align="center">
            <Text fontSize="md" color="gray.600">
              Date: {dateToString(result.created_at)}
            </Text>
          </VStack>
        </VStack>
        <Box
          position="absolute"
          bottom={6}
          left={0}
          right={0}
          textAlign="center"
        >
          <Text fontSize="sm" color="gray.500">
            Prepared by <strong>TheLastSpellingBee</strong> |
            www.thelastspellingbee.com
          </Text>
        </Box>
      </Box>
    </>
  );
};

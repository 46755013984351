import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  useToast,
  Spinner,
  Heading,
  Link,
  Switch,
} from "@chakra-ui/react";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'; 
import { MotionElement } from "../comon/MotionElement";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../redux/hooks/useTypedSelector";
import { resetAuth, signInAction } from "../../redux/slice/auth/authSlice";
import { SignIn as SignInType, SignInValidationSchema } from "./schema";

export const SignIn = () => {
  const { isSuccess, isError, errorMessage } = useAppSelector(
    (state) => state.auth,
  );
 
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({ 
    resolver: yupResolver(SignInValidationSchema),
    defaultValues: {
      email: "",
      password: ""
    },
  });

const handleSignInFeedBack = async () =>{
    if (isSuccess) {
      if (localStorage.getItem('isApiKeyNavigatePage')) { 
        const page_to_visit = localStorage.getItem('isApiKeyNavigatePage');
         navigate('/'+page_to_visit);
      }else{
        navigate(`/dashboard`);
      }
    }else if(isError && !isSuccess){
  
      toast({
        title: "Error SignIn",
        description: errorMessage,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      await dispatch(resetAuth);
    }
}

const onSubmitHandler = async (data: SignInType) => {
   try {
    await dispatch(signInAction(data));
    await handleSignInFeedBack();
   } catch (error: any) {
    console.log(error.message);
   }
};

  
const handleGoogleSignIn = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res =  await axios.get("https://www.googleapis.com/oauth2/v3/userinfo",{
          headers: {
            Authorization: `Bearer ${response.access_token}`
          }
        });
        const data = {
          email: res.data.email,
          company_name: res.data.name,
          password: "",
          isGoogleLogIn: true,
        }
        await dispatch(signInAction(data));
        await handleSignInFeedBack();

      } catch (error) {
        
      }
    }
});


  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"#dbeafe"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Box rounded={"lg"} bg={"#FFF"} boxShadow={"lg"} p={8}>
          <Box as={"form"} mt={0} onSubmit={handleSubmit(onSubmitHandler)}>
            <Flex direction="column" w="100%" background="transparent">
            <MotionElement type="fadeInX">
              <Heading fontSize="32px" mb={5}>
                Welcome Back!
              </Heading>
              </MotionElement>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  type="email"
                  {...register("email")}
                  placeholder="Your email address"
                  id="email"
                  isInvalid={!!errors.email}
                  required
                  mb={4}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Password
                </FormLabel>
                <Input
                  type="password"
                  {...register("password")}
                  placeholder="Your password"
                  id="password"
                  required
                  isInvalid={!!errors.password}
                  mb={4}
                />
                <FormControl display="flex" alignItems="center">
                  <Switch id="remember-login" colorScheme="blue" me="10px" />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    ms="1"
                    fontWeight="normal"
                  >
                    Remember me
                  </FormLabel>
                </FormControl>
                <Button
                  type="submit"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  colorScheme="blue"
                >
                  {isSubmitting ? <Spinner /> : " SIGN IN"}
                </Button>
                <Button
                  onClick={() => handleGoogleSignIn()}
                  w="100%"
                  h="45"
                  mb="20px"
                  mt="10px"
                >
                  <Flex align="center">
                  <div>
                    <svg
                      viewBox="0 0 48 15"
                      width="48"
                      height="78"
                      style={{ marginRight: "10px" }}
                    >
                      <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                        <path
                          fill="#4285F4"
                          d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                        ></path>
                        <path
                          fill="#EA4335"
                          d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                        ></path>
                      </g>
                    </svg>
                  </div>
                    Sign In with Google
                  </Flex>
                </Button>
                {/* <button onClick={logOutFromGoogle}>logOutFromGoogle</button> */}
              </FormControl>
              <Flex
                flexDirection="column"
                justifyContent="left"
                alignItems="left"
                maxW="100%"
                mt="0px"
              >
                <Text color={"gray.400"} fontWeight="medium">
                  Don't have an account?
                  <Link color={"#2b6cb0"} as="a" href="sign-up" ms="5px">
                    Sign Up
                  </Link>
                </Text>
                <Text color={"gray.400"} fontWeight="medium" mt={2}>
                  Forgot password?
                  <Link
                    color={"#2b6cb0"}
                    as="a"
                    href="forget-password"
                    ms="5px"
                  >
                    Recover password
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
};

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { InvitePlayers } from "./InvitePlayers";
import { Instructions } from "./Instructions";
import { StartGame } from "../../redux/service/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux/hooks/useTypedSelector";
import { useNavigate, useParams } from "react-router-dom";
import { startGameAction } from "../../redux/slice/game/spellingSlice";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import { CustomSelect } from "../comon/CustomSelect";
import ContentCard from "../nav/ContentCard";
import { yupResolver } from "@hookform/resolvers/yup";
import { SpellSchema } from "./schema";

export const Spell = () => {
  const { data } = useAppSelector((state: { newGame: any }) => state.newGame);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const { inviteLink } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const inviteId = data ? `${data[0]?.id}-${data[0]?.handle}` : inviteLink;

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(SpellSchema),
    defaultValues: {
      handle: inviteId,
      firstName: "",
      lastName: "",
      country: "",
    },
  });

  const onSubmitHandler = async (data: any) => {
    try {
      await dispatch(startGameAction(data as StartGame));
      toast({
        title: "Game created.",
        description: "We've created your game for you.",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      localStorage.removeItem("thelastspreult");
      navigate(`/spelling`);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  if (!inviteId) {
    return <Spinner />;
  }

  return (
    <ContentCard>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        {data ? <InvitePlayers inviteId={inviteId} /> : ""}

        <Box rounded={"lg"} bg={"gray.50"} boxShadow={"lg"} p={8}>
          <Instructions />
          <Box as={"form"} mt={0} onSubmit={handleSubmit(onSubmitHandler)}>
            <Stack spacing={4} mt={5}>
              <FormControl isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  {...register("firstName")}
                  id="userName"
                  placeholder="First Name"
                  required
                  isInvalid={!!errors.firstName}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  {...register("lastName")}
                  id="userName"
                  placeholder="Last Name"
                  required
                  isInvalid={!!errors.lastName}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  type="email"
                  {...register("email")}
                  placeholder="Your email address"
                  id="email"
                  isInvalid={!!errors.email}
                  required
                  mb={4}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Country Location</FormLabel>
                <CustomSelect
                  register={register}
                  id="country"
                  placeholder="Select Country"
                  defaultValue={""}
                  options={countryOptions}
                />
              </FormControl>
              <Stack spacing={10}>
                <Text textAlign={"left"} color={"blue.600"}>
                  Your name will appear on your certificate exactly as you enter
                  it. We will send your certificate to your email.
                </Text>
                {/* <Text textAlign={"left"}>
                  Your Journey to Unleash Your Inner Memory Maestro Begins Here
                </Text> */}
                <Button colorScheme={"blue"} type="submit">
                  {isSubmitting ? <Spinner /> : "Play"}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </ContentCard>
  );
};

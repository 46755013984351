import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import gameService from '../../service/game/gameService';
import { CreateGameGameData } from '../../service/types';


interface GetGameState {
  games: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: GetGameState = {
  games: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};


export const getGamesAction = createAsyncThunk('auth/games', async (companyId: number, thunkAPI) => {
  try {
    return await gameService.getGames(thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const updateGameAction = createAsyncThunk('auth/update', async (data: CreateGameGameData, thunkAPI) => {
  try {
    return await gameService.updateGame(data,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const deleteGameAction = createAsyncThunk('auth/delete', async (data: CreateGameGameData, thunkAPI) => {
  try {
    return await gameService.deleteGame(data,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const handleRejected = (state: GetGameState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};
const handleFulfilled = (state: GetGameState, action: any) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.games = action.payload; 
};
export const getGameSlice = createSlice({
  name: 'getGames',
  initialState,
  reducers: {
    resetAnswer: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGamesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGamesAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(getGamesAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(updateGameAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGameAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(updateGameAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(deleteGameAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGameAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(deleteGameAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      });
  },
});
export default getGameSlice.reducer;
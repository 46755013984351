import {
  Flex,
  Text,
  VStack,
  SimpleGrid,
  Heading,
  Spinner,
  Button,
  ButtonGroup,
  Img,
  useToast,
  Box,
  FormControl,
  FormLabel,
  Input,
  Center,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { calGameScore } from "../game-play/helper";
import { calculateTimeTaken } from "../game-play/SpellingResult";
import ContentCard from "../../nav/ContentCard";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CheckIQScoreSchema, CheckIQScoreType } from "./schema";
import { CertificateModal } from "./CertificateModal";
import { useIQScore } from "../../../redux/hooks/useIQScore";
import { GameResult } from "../../../redux/service/types";
import { TheLastSpellingBeeReCaptcha } from "the-last-spelling-bee-re-captcha";

export const CheckIQScore = () => {
  const itemsPerPage = 10;
  const {
    isLoading,
    resultItems,
    resultData,
    paginate,
    fetchIQScore,
    noResult,
    isCaptchaVerify, 
    setIsCaptchaVerify
  } = useIQScore(itemsPerPage);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [playerResult, setPlayerResult] = useState<GameResult>();


  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(CheckIQScoreSchema),
    // defaultValues: {
    //   email: "",
    // },
  });

  const onSubmit = async (email: CheckIQScoreType) => {
    try {
      await fetchIQScore(email.email);
    } catch (error: any) {
      toast({
        title: "Error",
        description:
          "An error occurred while fetching your IQ score. Please try again.",
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ContentCard>
      <Heading textAlign="center" mb={5} mt={20}>
        Check Your Memory IQ Score
      </Heading>
      <Center>
        <Box as={"form"} mt={0} onSubmit={handleSubmit(onSubmit)} w={"70%"}>
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Email
            </FormLabel>
            <Input
              type="email"
              {...register("email")}
              placeholder="Your email address"
              id="email"
              isInvalid={!!errors.email}
              required
              mb={4}
            />
          </FormControl>
          <TheLastSpellingBeeReCaptcha
            reCaptchaKey={"30815c93-03af-4a1b-80f9-79015de0ded8"}
            questionType="RANDOM"
            onVerifyCaptcha={(result: any) => {
              setIsCaptchaVerify(!result);
            }}
          />
          {!isCaptchaVerify ? (
            <Button
              type="submit"
              w="100%"
              h="45"
              mb="20px"
              color="white"
              mt="20px"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Center>
      {noResult ? (
        <Alert status="info">
          <AlertIcon />
          {noResult}
        </Alert>
      ) : (
        <>
          <Center>
            <SimpleGrid
              columns={{ base: 1, md: 1, lg: 1, xl: 1, "2xl": 1 }}
              spacing={5}
              borderRadius="lg"
              p={{ base: 0, md: 0, lg: 0, xl: 5, "2xl": 5 }}
              width={{
                base: "100%",
                md: "100%",
                lg: "100%",
                xl: "70%",
                "2xl": "70%",
              }}
            >
              {resultItems &&
                resultItems.map((game, index) => (
                  <Flex
                    key={index}
                    align="center"
                    justify="space-between"
                    bg={"white"}
                    borderRadius={"md"}
                    boxShadow={"lg"}
                    p={3}
                  >
                    <Flex align="center">
                      <Text mr={3} fontSize="2xl">
                        <ReactCountryFlag countryCode={game.country ?? "CA"} />
                      </Text>
                      <VStack align="start" spacing={0}>
                        <Text fontWeight="medium">{game.userName}</Text>
                        <Text color="gray.500" fontSize="sm">
                          {calculateTimeTaken(game)}
                        </Text>
                      </VStack>
                    </Flex>
                    <Flex fontWeight="bold" fontSize="lg" color={"blue.600"}>
                      <Text
                        as={"span"}
                        mr={
                          calGameScore(game.numberOfQuestions, game.score) >= 85
                            ? -4
                            : 0
                        }
                      >
                        M-IQ {calGameScore(game.numberOfQuestions, game.score)}{" "}
                      </Text>
                      {calGameScore(game.numberOfQuestions, game.score) >=
                        89 && <Img src="images/crown.svg" mt={-9} />}
                    </Flex>
                    <Button
                      onClick={() => {
                        setPlayerResult(game);
                        setIsModalOpen(true);
                      }}
                      colorScheme="blue"
                      variant={"outline"}
                      size="sm"
                      ml={3}
                    >
                      View Certificate
                    </Button>
                  </Flex>
                ))}
            </SimpleGrid>
          </Center>
          <Flex justify="center" mt={4}>
            <ButtonGroup size="sm" isAttached variant="outline">
              {Array.from({
                length: Math.ceil(resultData.length / itemsPerPage),
              }).map((_, index) => (
                <Button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  cursor="pointer"
                  colorScheme="blue"
                  size="lg"
                >
                  {index + 1}
                </Button>
              ))}
            </ButtonGroup>
          </Flex>
          <CertificateModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            gameResult={playerResult ?? ({} as GameResult)}
          />
        </>
      )}
    </ContentCard>
  );
};

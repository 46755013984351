import { FC, useEffect } from "react";
import {
  Box,
  Heading,
  Table,
  Text,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  Button,
  Link,
  Img, 
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux/hooks/useTypedSelector";
import { GameResultAction } from "../../redux/slice/user/gameResultSlice";
import { GameResult as Result } from "../../redux/service/types";
import { calGameScore, calculatePoints, getCategory } from "../spelling/game-play/helper";
import { calculateTimeTaken } from "../spelling/game-play/SpellingResult";
import ReactCountryFlag from "react-country-flag";
import ContentCard from "../nav/ContentCard";

export const GameResult: FC = () => {
  const { data, isLoading } = useAppSelector(
    (state: { gameResult: any }) => state.gameResult,
  );
  const { gameId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GameResultAction(gameId as unknown as number));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  if (isLoading) {
    return <Spinner />;
  }
  const sortedData = (data?.data || [])
    .slice()
    .sort((a: { score: number }, b: { score: number }) => b.score - a.score);

  return (
    <ContentCard>
      <Box borderRadius="15px" px="0px" display="flex">
        <Heading fontSize="32px" mb={5} textAlign={"center"}>
          Results
        </Heading>
      </Box>
      <Text  fontWeight={600} mb={4} fontSize={18}>
        M-IQ Name: <Text as={"span"}>{sortedData[0]?.gameName}</Text>
      </Text>
      <Text fontWeight={600} mb={4} fontSize={18}>
        Category: <Text as={"span"}>{getCategory(sortedData[0]?.gameCategory)}</Text>
      </Text>
      <TableContainer bg={"white"} p={9}>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th></Th>
              <Th fontSize={18}>User</Th>
              <Th fontSize={18}>Points</Th>
              <Th fontSize={18}>M-IQ</Th>
              <Th fontSize={18}>Time Completed</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedData &&
              sortedData.map((game: Result, index: any) => (
                <Tr key={index} boxShadow={"md"} top={9}>
                  <Td><ReactCountryFlag countryCode={game.country} /></Td>
                  <Td>{game.userName}</Td>
                  <Td>
                    {calculatePoints(
                      parseInt(game.numberOfQuestions),
                      game.score,
                      game.timeCompleted,
                    )}
                  </Td>
                  <Td>
                    {calGameScore(game.numberOfQuestions, game.score) >= 85 && 
                      <Img src="/images/crown.svg" mb={0} />}
                    {calGameScore(game.numberOfQuestions, game.score)}
                  </Td>
                  <Td>{calculateTimeTaken(game)}</Td>
                  <Td>
                  <Link
                    href={`/result-details/${btoa(JSON.stringify(game.gameCategory === "teamBonding" ? sortedData : game))}`}
                    color="#2b6cb0"
                    fontSize={{ base: 14, sm: 17 }}
                    me="10px"
                >
                  <Button h={8} colorScheme='blue' variant="outline">
                     Details
                  </Button>
                  </Link>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};

import {
  Box,
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
  SimpleGrid,
  Center,
} from "@chakra-ui/react";
import { MotionElement } from "../comon/MotionElement";

type Props = {
  children: React.ReactNode;
};

export const AboutSection = (props: Props) => {
  const { children } = props;

  return <Box width={"100%"}>{children}</Box>;
};

export const AboutSectionContent = (props: Props) => {
  const { children } = props;

  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
    >
      {children}
    </Stack>
  );
};

export const AboutSectionHeading = (props: Props) => {
  const { children } = props;

  return (
    <Heading as={"h3"} fontSize={"xl"}>
      {children}
    </Heading>
  );
};

export const AboutSectionText = (props: Props) => {
  const { children } = props;

  return (
    <Box
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
    >
      {children}
    </Box>
  );
};

export const RecaptchaHero = () => {
  return (
    <Container
      maxW={"100%"}
      bg={useColorModeValue("gray.100", "gray.700")}
      py={16}
      as={Stack}
      spacing={12}
    >
      <Stack spacing={0} align={"center"}>
        <MotionElement type="fadeInY">
          <Heading as="h2" color={"gray.600"}>
            <Text as={"span"}>It's Not Just</Text>{" "}
            <Text as={"span"} className="primary-color">
              {" "}
              a Game
            </Text>
          </Heading>
        </MotionElement>
      </Stack>
      <Center>
        <SimpleGrid
          columns={{ base: 1, md: 1, lg: 1, xl: 2, "2xl": 2 }}
          spacing={5}
          borderRadius="lg"
          p={5}
        >
          <AboutSection>
            <AboutSectionContent>
              <AboutSectionHeading>
                Your success is our highest priority
              </AboutSectionHeading>
              <AboutSectionText>
                <MotionElement type="fadeInX">
                  <Text as="p" fontSize="lg">
                    <Text as={"span"} color={"#2b6cb0"} fontWeight={600}>
                      ThelastSpellingBee App
                    </Text>{" "}
                    is designed to enhance your ability to remember and retain
                    information. Whether you're preparing for a spelling bee or
                    simply looking to improve your language skills, our app
                    provides a user-friendly experience. With interactive
                    features and personalized learning, success is just a step
                    away.
                  </Text>
                </MotionElement>
              </AboutSectionText>
            </AboutSectionContent>
          </AboutSection>
          <AboutSection>
            <AboutSectionContent>
              <AboutSectionHeading>
                Preventing spam and abuse with TheLastSpellingBee API
              </AboutSectionHeading>
              <AboutSectionText>
                <MotionElement type="fadeInX">
                  <Text as="p" fontSize="lg">
                    TheLastSpellingBee's API is a powerful tool to distinguish
                    between humans and bots on websites. By integrating{" "}
                    <Text as={"span"} color={"#2b6cb0"} fontWeight={600} ml={1}>
                      ThelastSpellingBee's Re-CAPTCHA
                    </Text>{" "}
                    into your website, you can prevent automated programs from
                    accessing the site, ensuring a secure and spam-free
                    environment for legitimate users. The API is designed to be
                    simple for humans and challenging for bots, making it an
                    effective solution against malicious activities.
                  </Text>
                </MotionElement>
              </AboutSectionText>
            </AboutSectionContent>
          </AboutSection>
        </SimpleGrid>
      </Center>
    </Container>
  );
};

import { FC } from "react";
import { AspectRatio, Box, Heading, Text } from "@chakra-ui/react";

type VideoProps = {
  videoUrl: string;
};

export const VideoAboutTheLastSpellingBee: FC<VideoProps> = ({ videoUrl }) => {
  const videoId = videoUrl.split("v=")[1].split("&")[0];

  return (
    <Box width={"100%"} p={4}>
      <Heading
        color={"gray.600"}
        fontSize={["lg", "xl", "xl", "3xl"]} 
        textAlign={["center", "center", "left"]} 
      >
        <Text as={"span"}>About</Text>{" "}
        <Text as={"span"} className="primary-color">
          TheLastSpellingBee
        </Text>
      </Heading>
      <AspectRatio maxW="100%" ratio={16 / 9} mt={6}>
        <iframe
          title="About TheLastSpelling Bee Video"
          src={`https://www.youtube.com/embed/${videoId}`}
          allowFullScreen
        />
      </AspectRatio>
    </Box>
  );
};

import  { FC } from "react";
import {
  Stack,
  Heading,
  Button,
  Text,
  Box,
  CloseButton,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import {
  CreateGameProps,
} from "./types";
import { createNewGameAction } from "../../redux/slice/game/startNewGameSlice";
import { CreateGameGameData } from "../../redux/service/types";
import {
  useAppDispatch,
} from "../../redux/hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { GameValidationSchema } from "../user/action/GameValidationSchema";
import { CreateGameForm } from "../comon/CreateGameForm";

export const CreateGame: FC<CreateGameProps> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, errors },
  } = useForm<CreateGameGameData>({ 
    resolver: yupResolver(GameValidationSchema("unAuth")),
  });
  if (!isOpen) {
    return null;
  }

  const onSubmitHandler = async (data: any) => {
    try {
     await dispatch(createNewGameAction(data));
     toast({
       title: "Game created.",
       description: "We've created your M-IQ for you.",
       status: "success",
       position: "top-right",
       duration: 9000,
       isClosable: true,
     });
     navigate(`/spell`);
    } catch (error: any) {
     console.log(error.message);
    }
 };


  return (
    <Stack
      bg={"#FFF"}
      boxShadow={"xl"}
      borderRadius={5}
      marginTop={9}
      p={{ base: 4, sm: 6, md: 8 }}
      spacing={{ base: 8 }}
      maxW={{ lg: "lg" }}
    >
      <Stack spacing={4}>
        <Text>
          <CloseButton size="sm" float="right" onClick={onClose} />
        </Text>
        <Heading
          color={"gray.600"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          Create M-IQ Test
          <Text
            as={"span"}
            bgGradient="linear(to-r, red.400,#F26B3A)"
            bgClip="text"
          >
            !
          </Text>
        </Heading>
        <Text color={"gray.600"} fontSize={{ base: "sm", sm: "md" }}>
          Join the memory revolution, where fun meets cognitive growth.
        </Text>
      </Stack>
      <Box
        as={"form"}
        mt={0}
        onSubmit={handleSubmit(onSubmitHandler)}
        data-testid="create-game-form"
      >
        <CreateGameForm register={register} errors={errors} control={control} type={"unAuth"} />
        <Button
          fontFamily={"heading"}
          mt={9}
          colorScheme="blue"
          size="lg"
          w={"full"}
          type="submit"
        >
          {isSubmitting ? <Spinner /> : "Submit"}
        </Button>
      </Box>
    </Stack>
  );
};

import * as yup from 'yup';
import { InferType } from 'yup';

export const SpellSchema = yup.object().shape({
  handle: yup.string().required('First Name is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required'),
  country: yup.string().required('Country  is required'),
});
export type Spell = InferType<typeof SpellSchema>;


export const config = {
    baseUrl: {
      default:
        process.env.NODE_ENV === "development"
          ? "http://localhost:5002/api/code-bender/"
          : "https://thelastspellingbee-api.onrender.com/api/code-bender/",
    },
    socketUrl:
      process.env.NODE_ENV === "development"
        ? "http://localhost:5002" 
        : "https://thelastspellingbee-socket.onrender.com", 
  };
  
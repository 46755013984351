import { useState, useEffect } from "react";
import { GameResult } from "../service/types";
import { IQScoreAction } from "../slice/game/iQScoreSlice";
import { useAppSelector, useAppDispatch } from "./useTypedSelector";

export const useIQScore = (itemsPerPage: number) => {
  const { data, isLoading } = useAppSelector(
        (state: { iqScore: any }) => state.iqScore
      );
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resultData, setResultData] = useState<GameResult[]>([]);
  const [resultItems, setResultItems] = useState<GameResult[]>([]);
  const [noResult, setNoResult] = useState<string | null>(null);
  const [isCaptchaVerify, setIsCaptchaVerify] = useState<boolean>(true);

  useEffect(() => {
    const results = (data?.data || [])
      .slice()
      .sort((a: { id: number }, b: { id: number }) => b.id - a.id);
    setResultData(results);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = results.slice(indexOfFirstItem, indexOfLastItem);
    setResultItems(currentItems);
  }, [currentPage, data, isLoading, itemsPerPage]);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const fetchIQScore = async (email: string) => {
    setNoResult(null);
    setResultItems([]); 
  
    const result = await dispatch(IQScoreAction({ email })).unwrap();

    if (result?.data?.length === 0) {
      setNoResult(`No IQ certificate NOTE found for ${email}! Please check your email or try another email.`);
    } else {
      setNoResult(null);
    }
    setIsCaptchaVerify(true);
  };
  

 
  return {
    data,
    isLoading,
    resultData,
    resultItems,
    currentPage,
    paginate,
    fetchIQScore,
    noResult,
    isCaptchaVerify, 
    setIsCaptchaVerify
  };
};
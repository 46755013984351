
export type CreateGameProps = {
    isOpen: boolean;
    onClose: () => void;
}

export const categoryOPtions = [
    { value: "education", label: "Education" },
    { value: "forFun", label: "For fun" },
    { value: "teamBonding", label: "Team bonding" },
    { value: "stressTest", label: "Stress Test" },
    { value: "depressionTest", label: "Depression Test" },
  ];
export const questionTypeOptions = [
    { value: "characters", label: "Characters - Beginner" },
    { value: "numbers", label: "Numbers - Advanced" },
    { value: "random", label: "Random Char & Num - Professional" },
    { value: "complex", label: "Mixed Char & Num  - Ultimate" },
  ];

  export const wordLengthOptions = Array.from({ length: 9 }, (_, index) => ({
    value: `${index + 3}`,
    label: `${index + 3} Letter Words`,
  }));
export const numberOfQuestionsOptions = Array.from({ length: 91 }, (_, index) => ({
    value: `${index + 10}`,
    label: `${index + 10} Questions`,
  }));
export const gameTimeOptions = Array.from({ length: 118 }, (_, index) => ({
    value: `${index + 0}`,
    label: `${index + 1} Minutes`,
  }));
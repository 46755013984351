import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import gameService from '../../service/game/gameService';
import { AnswerGame } from '../../service/types';

interface AnswerGameState {
  answerData: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: AnswerGameState = {
  answerData: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};

const handleRejected = (state: AnswerGameState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};

export const answerGameAction = createAsyncThunk('auth/answerGame', async (data: AnswerGame, thunkAPI) => {
  try {
    return await gameService.answerSpelling(data);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const spellingSlice = createSlice({
  name: 'answerGame',
  initialState,
  reducers: {
    resetAnswer: (state) => initialState,

  },
  extraReducers: (builder) => {
    builder
      .addCase(answerGameAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(answerGameAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.answerData = action.payload; 
      })
      .addCase(answerGameAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});

export const { resetAnswer } = spellingSlice.actions;
export default spellingSlice.reducer;
import React, { FC } from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  useToast,
  Box,
  Spinner,
  Stack,
  Heading,
  ModalFooter,
  Center,
} from "@chakra-ui/react";
import { useForm} from "react-hook-form"; 
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { Game, CreateGameGameData} from "../../../redux/service/types";
import { GameDefaultValues } from "./GameValidationSchema";
import { deleteGameAction } from "../../../redux/slice/user/manageGameSlice";

interface DeleteGameProps {
  game: Game;
}
export const DeleteGame: FC<DeleteGameProps> = ({
    game
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CreateGameGameData>({ 
    defaultValues: GameDefaultValues(game as CreateGameGameData),
  });
 
  const onSubmitHandler = async (data: any) => {
    try {
        await dispatch(deleteGameAction(data));
        toast({
          title: "Game Deleted.",
          description: "Game deleted successfully",
          status: "success",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });
        navigate(`/`);
    } catch (error: any) {
        console.log(error.message);
    }
  };

  return (
    <>
      <Text as={"a"} href="#" color={"red.600"} onClick={onOpen}>
        <Text ml={1}>Delete Game</Text>
      </Text>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent p={3}>
          <ModalHeader>
          
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
              <Stack spacing={4}>
              <Heading fontSize={20}>
                Are you sure you want to delete this game
                 <Text as={"span"} color={"red.600"} ml={2}>{game.gameName}</Text>?
              </Heading>
              </Stack>
              <ModalFooter>
              <Box
                    as={"form"}
                    mt={0}
                    width={"100%"}
                    onSubmit={handleSubmit(onSubmitHandler)}
                    >
                <Center>
                <Button
                    type="submit"  colorScheme="blue">
                    {isSubmitting ? <Spinner /> : "Yes"}
                </Button>
                <Button colorScheme='blue' ml={3}  onClick={onClose}>
                    Cancel
                </Button>
                </Center>
                </Box>
          </ModalFooter>
            
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};